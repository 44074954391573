<template>
    <TheHeader />
    <div v-if="!loadingState">
        <div v-if="hasCustomerAccessToApplicationVetter === true">
            <div v-if="hasPermissionToUpdateApplicationVetter">
                <SidebarNavigation :menus="sidebarMenus" :collapsed="isCollapsed" :activeMenu="activeMenu"
                    @menuSelected="handleMenuSelected" @collapseChanged="handleCollapsed" />
                <div :class="['sidebar-content-wrapper', { 'sidebar-collapsed': isCollapsed }]">
                    <div class="sidebar-content">
                        <Card v-if="activeMenu === 'inbox'">
                            <template #content>
                                <ApplicationVetterList :flagNamesList="flagNamesList" :exemptionTypes="exemptionTypes"
                                    :locked="'false'" :queue="['pending']" />
                            </template>
                        </Card>

                        <Card v-if="activeMenu === 'deferred'">
                            <template #content>
                                <ApplicationVetterList :flagNamesList="flagNamesList" :exemptionTypes="exemptionTypes"
                                    :locked="'true'" :queue="['deferred']" />
                            </template>
                        </Card>
                        <Card v-if="activeMenu === 'archive'">
                            <template #content>
                                <ApplicationVetterList :flagNamesList="flagNamesList" :exemptionTypes="exemptionTypes"
                                    :locked="'true'" :queue="['approved', 'denied']" />
                            </template>
                        </Card>
                        <Card v-if="activeMenu === 'activity'">
                            <template #content>
                                <ApplicationVetterDashboard />
                            </template>
                        </Card>
                        <Card v-if="activeMenu === 'assigned_to_me'">
                            <template #content>
                                <ApplicationVetterList :flagNamesList="flagNamesList" :exemptionTypes="exemptionTypes"
                                    :locked="'true'" :queue="['assigned_to_me']" />
                            </template>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="hasCustomerAccessToApplicationVetter === false">
            <br>
            <AppVetSalesPage />
        </div>
        <div v-else class="d-block text-center m-3">
            <loading-icon class="d-inline" /> Hang tight! We're fetching your data as quickly as possible.
        </div>
    </div>
    <AppMonitor />
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue"
import TheHeader from "@/components/TheHeader.vue";
import AppMonitor from "@/components/AppMonitor.vue"
import ApplicationVetterList from "@/components/ApplicationVetter/ApplicationVetterList.vue";
import ApplicationVetterDashboard from "@/components/ApplicationVetter/ApplicationVetterDashboard.vue";
import SidebarNavigation from "@/components/Shared/SidebarNavigation.vue"
import { validateUserPermission, getApiErrorMessage } from "@/helpers/common";
import { useProfile } from "@/stores/profile"
import AppVetSalesPage from "@/components/Shared/AppVetSalesPage.vue";
import Card from 'primevue/card';
import { useAPI } from "@/helpers/services/api"
import { toast } from "@/helpers/toast";
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import type { SidebarNavigationMenu } from "@/helpers/interface/candidates";

const api = useAPI()
const storeProfile = useProfile();
const flagNamesList = ref<[]>([])
const exemptionTypes = ref<[]>([])

const activeMenu = ref('inbox')
const sidebarMenus = [
    { label: "Inbox", iconClass: "fas fa-inbox", id: "inbox" },
    { label: "Deferred", iconClass: "fas fa-clock", id: "deferred" },
    { label: "Archive", iconClass: "fas fa-archive", id: "archive" },
    { label: "Activity", iconClass: "fas fa-chart-line", id: "activity" },
    { label: "Assigned To Me", iconClass: "fas fa-user-tag", id: "assigned_to_me" }
] as SidebarNavigationMenu[]
const isCollapsed = ref(false)
const handleMenuSelected = (menuId: string) => activeMenu.value = menuId
const handleCollapsed = (collapsed: boolean) => isCollapsed.value = collapsed
const loadingState = computed(() => storeProfile.loadingState)
const hasPermissionToUpdateApplicationVetter = computed(() => validateUserPermission("update", "applications"))
const hasCustomerAccessToApplicationVetter = computed(() => storeProfile.getCustomerAccess?.application_vetter || validateUserPermission("read", "customers"))

const fetchDropdownFlagList = async () => {
    try {
        const response = await api.get("/applications/flag_names")
        flagNamesList.value = response.data
    }
    catch (error: any) {
        flagNamesList.value = []
        toast.error(getApiErrorMessage(error))
    }
}

const fetchDropdownExemptionList = async () => {
    try {
        const response = await api.get("/applications/exemption_types")
        exemptionTypes.value = response.data
    }
    catch (error: any) {
        exemptionTypes.value = []
        toast.error(getApiErrorMessage(error))
    }
}

onMounted(async () => {
    await storeProfile.fetchUserPermissions()
    await Promise.all([
        fetchDropdownFlagList(),
        fetchDropdownExemptionList()
    ])
})
</script>
