import { ref, computed } from "vue"
import { defineStore } from "pinia";
import { useAPI } from "@/helpers/services/api"
import type { Customer } from "@/helpers/interface/admin-page"
import { StorageKeyEnum, storageManager } from "@/helpers/storageManager";
import { validateUserPermission } from "@/helpers/common";

export const useProfile = defineStore("profile", () => {

    const api = useAPI()
    const customerAccess = ref<Customer>()
    const userPermissions = ref<string[]>([])
    const loadingState = ref(false)

    const fetchCustomerAccess = async () => {
        loadingState.value = true
        await api.get("/customers/current")
            .then(response => {
                customerAccess.value = response.data as Customer
            })
            .catch(() => {
                customerAccess.value = { customer_id: "", name: "-" }
            })
            .finally(() => loadingState.value = false)
    }

    const fetchUserPermissions = async () => {
        loadingState.value = true
        await api.get("/users/auth0/permissions")
            .then(response => {
                userPermissions.value = response.data
            })
            .finally(() => loadingState.value = false)
    }

    const getCustomerAccess = computed(() => customerAccess.value)
    const getCurrentPermissions = computed(() => userPermissions.value)
    const enableBetaFeatures = computed(() => {
        loadingState.value = true
    
        const customerBetaFeatureEnabled = customerAccess.value?.enable_beta_features
        storageManager.setItem(StorageKeyEnum.EnableBetaFeaturesCL, customerBetaFeatureEnabled ? "on" : "off")
    
        const enabled = customerBetaFeatureEnabled || storageManager.getItem(StorageKeyEnum.EnableBetaFeatures) === "on"
    
        loadingState.value = false
        return enabled
    })

    const investigationServicesEnabled = computed((): boolean => customerAccess.value?.investigation_services || validateUserPermission("read", "customers"))

    return {
        customerAccess,
        userPermissions,
        loadingState,
        fetchCustomerAccess,
        fetchUserPermissions,
        getCustomerAccess,
        getCurrentPermissions,
        enableBetaFeatures,
        investigationServicesEnabled
    }

})
